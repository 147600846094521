@import '../../../../core/styles/mixins';

.confirm-page {
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 4;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 17px;
  color: #121212;
  padding-bottom: env(safe-area-inset-bottom);
  margin-top: 56px;

  &__scroll {
    overflow: scroll;
    padding-bottom: 40px;
    height: 100%;
  }

  &__thanks {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 16px 0;
  }

  &__description {
    margin: 16px;
    text-align: center;
    color: #000;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__item {
    &__title {
      font-weight: 600;
      margin-bottom: 6px;
      font-size: 12px;
    }

    &__value {
      font-size: 12px;
    }
  }
}

@include bp(lg) {
  .confirm-page {
    margin-top: 0;

    &__description {
      margin: 16px 68px 16px 68px;
    }

    &__custom-button {
      position: absolute;
      bottom: 16px;
      width: 448px;
    }
  }
}
